<template>
  <LayoutBaseLayout
    v-if="isBusinessError"
    name="wide"
  >
    <div>
      <agency-not-found v-if="isAgencyNotFoundError" />
    </div>
  </LayoutBaseLayout>
  <div
    v-else
    class="container"
  >
    <h2>Nous sommes désolés, la page demandée est introuvable.</h2>
    <br>
    <div v-if="config.public.dev">
      <pre>{{ error }}</pre>
      <div v-html="error.stack" />
    </div>
    <uikit-button
      type="primary"
      @click="handleError"
    >
      Retour à l'accueil
    </uikit-button>
  </div>
</template>

<script setup>
import { UikitButton } from '@hz/ui-kit'

const config = useRuntimeConfig()

const props = defineProps({
  error: Object,
})

const isNotFoundError = computed(() => props.error?.statusCode === 404)

const isAgencyNotFoundError = computed(
  () => isNotFoundError.value && props.error?.statusMessage === 'agency-not-found',
)

const isBusinessError = computed(() => isAgencyNotFoundError.value)

const handleError = () => clearError({ redirect: '/' })
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  :deep(pre) {
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid #f36d33;
    color: #666;
    page-break-inside: avoid;
    font-size: 15px;
    margin-bottom: 1.6em;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
    max-width: 80vw;
  }
}
</style>
